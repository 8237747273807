export default class InputGetSpeakers
{
  constructor(photo, name, info, urlSpeaker, isSponsor) 
  {
    this.photo = photo;
    this.name = name;
    this.info = info;
    this.url = urlSpeaker;
    this.isSponsor = isSponsor;
  }    
}