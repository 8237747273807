<template>
  <div>
    <div class="actions row col-lg-12 mb-5 p-0 m-0 justify-content-end">
      <div class="actions-right row col-lg-6 row justify-content-end p-0 m-0">
        <button type="button" class="btn btn-primary" @click="addSpeaker">{{ (' Add Speaker') }}</button>
      </div>
    </div>
    <CTableWrapper ref="table" hover striped :caption="caption" :items="items" :actions="actions" :fields="fields" clickable-rows @row-clicked="rowClicked" @action-clicked="actionClicked" @update-requested="updateItemsRequested" :loading="loading">
    </CTableWrapper>
  </div>
</template>
 ««
<script>
import { mapState, mapActions } from 'vuex'
import CTableWrapper from '../controls/PaginatedTable.vue'
import router from '@/router';
import InputGetSpeakers from "@/classes/InputGetSpeakers";

export default {
  components: { CTableWrapper },
  data () {
    return {
      caption: ('Speakers'),
      fields: [
        { key: 'photo', label: ('Photo'), _classes: 'font-weight-bold', sortable: false },
        { key: 'name', label: ('Name'), _classes: 'font-weight-bold', sortable: false },
        { key: 'info', label: ('Info'), _classes: 'font-weight-bold', sortable: false },
        { key: 'url', label: ('Url'), _classes: 'font-weight-bold', sortable: false },
        { key: 'isSponsor', label: ('Sponsor'), _classes: 'font-weight-bold', sortable: false},
        { key: 'level', label: ('Level'), _classes: 'font-weight-bold', sortable: false}
      ],
      actions: [
        { key: 'view', label: ('view') },
        { key: 'remove', label: ('remove') }
      ],
      loading: false
    }
  },
  computed: {
    ...mapState('speakers', ['listOutput']),
    ...mapState('speakers', ['removeTask']),
    speakersInfo: function() {
      return this.listOutput  ? this.listOutput : null;
    },
    items: function()
    {
      return this.speakersInfo ? this.speakersInfo : [];
    },
  },
  mounted: function()
  {
    this.updateItemsRequested();
  },
  methods: {
    ...mapActions('speakers', ['getList']),
    ...mapActions('speakers', ['removeSpeaker']),
    rowClicked (item, index) {
      this.$router.push({name: "EditSpeakers", params: { speaker: item } });
    },
    actionClicked(item, action){
      switch(action.key)
      {
        case 'view':
        {
          this.$router.push({name: "EditSpeakers", params: { speaker: item } });
          break;
        }
        case 'remove':
          {
            if(confirm("Wanna remove this speaker?")){
              this.removeSpeaker(item.id);
          }
          break;
        }
        default:
        {
          throw "The provided action is not supported!"; 
        }
      }
    },
    updateItemsRequested()
    {
      //Make request
      this.loading = true;

      let input = new InputGetSpeakers();
      this.getList(input); 
    },
    addSpeaker () 
    {
      router.push({ path: '/speakers/add' });
    },
  },
  watch:
  {
    removeTask: function(val)
    {
      // Speaker was deleted, reload list
      if(val)
      {
        this.updateItemsRequested();
      }
    },
    listOutput: function(val)
    {
      if(val){
        this.loading = false;
      }
    }
  }
}
</script>

 
<style>
th
{
  border-top: none !important;
}

tr
{
  cursor: pointer;
}

.status-button
{
  border: none;
  padding: 0;
  text-decoration: underline;
  color: #321fdb;
}
</style> 